@font-face {
    font-family: 'Montserrat-Reg';
    src: url('../assets/Montserrat-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Montserrat-Light';
    src: url('../assets/Montserrat-Italic-VariableFont_wght.ttf');
}
* {
    font-family: 'Montserrat-Reg';
}
.landing_container {
    overflow-x: hidden;
}
.first_section {
    width: 100vw;
    height: 105vh;
    position: relative;
    background-image: url('../assets/landing_five.svg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bottom_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 95%;
    height: 75%;
}
.bottom_container h1 {
    font-weight: 200;
    margin-top: 5%;
    font-size: 42px;
    font-family: "Montserrat-Light";
}
.header {
    width: 95%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left_header_container {
    display: flex;
    flex-direction: row;
}
.left_header_container img, .left_footer_container img {
    width: 75px;
    height: 75px;
}
.flex_column {
    display: flex;
    flex-direction: column;
}
.flex_column h3 {
    font-weight: 300;
}
.title_container h1 {
    font-weight: 500;
}
.landing_container h1, h3, p{
    margin: 0;
    color: white;
}
.header_button_container button {
    background: none;
    color: white;
    font-size: large;
    border: none;
    cursor: pointer;
    width: 120px;
    padding: 15px
}
.header_button_container button:hover {
    border-bottom: 1px solid white;
}
.learn_more {
    grid-column-start: 2;
    grid-column-end: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: end;
    justify-content: end;
}
.learn_more img {
    width: 50px;
}
.thin_section {
    background: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 25px;
}
.thin_section h1, .thin_section p {
    color: #4B4B4B;
}
.thin_section h1 {
    width: 20%;
    font-family: 'Montserrat-Light';
    font-weight: 300;
}
.thin_section p {
    width: 60%;
}
.iconHolder {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 25%;
    justify-items: center;
    text-align: center;
    align-items: center;
    height: 150px;
}
.iconHolder p {
    align-self: flex-start;
}
.iconHolder img {
    width: 50px;
    height: 50px;
}
.third_section {
    background-image: url('../assets/infographic.svg');
    background-position: center;
    background-size: cover;
    height: 616px;
}
.fourth_section {
    background-image: url('../assets/section_4.svg');
    background-position: center;
    background-size: cover;
    height: 616px;
}
.fifth_section {
    background-image: url('../assets/section_5.svg');
    background-position: center;
    background-size: cover;
    height: 616px;
}
.footer {
    background: #415A77;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.left_footer_container {
    padding: 25px;
}
.right_footer_container {
    padding: 25px;
}
.right_footer_container h3 {
    font-size: 24px;
    font-family: 'Montserrat-Light';
    font-weight: 400;
    padding-block: 20px;
}
.locations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.locations p {
    font-weight: 200;
    padding-right: 10px;
}
.socials_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block: 5px;
}
.socials_container img {
    width: 25px;
    height: 25px;
}

@media only screen and (max-width: 950px) {
    .left_header_container img {
        width: 50px;
        height: 50px;
    }
    .left_header_container h1 {
        font-size: 20px;
    }
    .left_header_container h3 {
        font-size: 16px;
    }
    .header_button_container button {
        width: 100px;
        font-size: 14px;
    }
    .bottom_container h1 {
        font-size: 32px;
    }
    .first_section {
        background-image: url('../assets/landing_five_800.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .third_section {
        background-image: url('../assets/infographic_800.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .fourth_section {
        background-image: url('../assets/section_4_800.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .fifth_section {
        background-image: url('../assets/section_5_800.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .iconHolder p {
        font-size: x-small;
    }
  }

  @media only screen and (max-width: 750px) {
    .thin_section h1 {
        font-size: 20px;
    }
    .thin_section p {
        font-size: 14px;
    }
    .iconHolder p {
        font-size: x-small;
    }
  }
  @media only screen and (max-width: 600px) {
    .left_header_container img {
        width: 35px;
        height: 35px;
    }
    .left_header_container h1 {
        font-size: 16px;
    }
    .left_header_container h3 {
        font-size: 12px;
    }
    .thin_section {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-block: 25px;
        padding-inline: 0px;
    }
    .thin_section h1, .thin_section p, .iconHolder {
        width: 80%;
    }
    .thin_section p {
        font-size: small;
    }
    .thin_section h1 {
        padding-bottom: 5px;
    }
    .first_section {
        background-image: url('../assets/landing_five_600.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .fourth_section {
        background-image: url('../assets/section_4_600.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .fifth_section {
        background-image: url('../assets/section_5_600.svg');
        background-position: center;
        background-size: cover;
        height: 476px;
    }
    .right_footer_container h3, .right_footer_container p, .locations p {
        font-size: small;
    }
    .left_footer_container p {
        font-size: small;
    }
  }