.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/sign_in_background.svg');
    background-size: cover;
}
.form_container img, .realtor_info_content img {
    width: 150px;
    height: 150px;
}
.form_container button {
    border: none;
    background: none;
    margin-top: 15px;
    cursor: pointer;
    color: white;
}
.form_container button:hover {
    color: #415A77;
}
.sign_in_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff99;
    border-radius: 10px;
    padding: 75px;
}
.sign_in_form input {
    margin-bottom: 5px;
    padding: 10px;
    border-width: 0px 0px 1px 0px;
    background: none;
    width: 80%;
}
.sign_in_form button {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    color: #0D1B2A;
    width: 80%;
}
#realtor_btn {
    color: #0D1B2A;
    background: none;
    width: 275px;
    padding: 10px;
    font-size: 12px;
    text-align: center;
}
#realtor_btn:hover {
    color: #415A77;
}
.dashboard_container, .realtor_dash_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background-image: url("../assets/sign_in_background.svg");
}
.nav_container {
    width: 20%;
    height: 100%;
    background-color: #778DA9DD;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.nav_container img {
    width: 50px;
    height: 50px;
}
.nav_container button {
    background: none;
    border: none;
    color: #0D1B2A;
}
.nav_container button:hover {
    color: #415A77;
    border-right: 1px solid white;
}
.logo_holder {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo_holder h3 {
    font-size: 18px;
}
.logo_holder p {
    font-size: 14px;
    font-weight: 200;
}
.content_container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card_container {
    height: 90%;
    width: 95%;
}
.card_container h1 {
    color: #0D1B2A;
    margin-block: 0;
    font-weight: 300;
    font-family: 'Montserrat-Light';
    font-size: 24px;
}
.card_container h3 {
    color: #0D1B2A;
    font-weight: 300;
    font-family: 'Montserrat-Light';
}
.verification_table {
    display: flex;
    width: 100%;
    height: 90%;
    background: #E0E1DDDD;
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-between;
}
.table_header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 15%;
    width: 100%;
    background-color: #ffffff99;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    align-items: center;
    justify-items: center;
}
.table_header h3 {
    color: #0D1B2A;
    font-family: 'Montserrat-Reg';
    font-weight: 600;
}
.table_content {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.card_holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid white;
}
.card_holder p {
    padding: 10px;
    text-align: center;
}
.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_container {
    width: 100%;
    height: 100%;
    background: #00000099;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_content {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 95%;
}
.card_list_view {
    height: 75%;
    width: 100%;
}
.pagination_holder {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info_header {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.info_header h1 {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
}
.info_header p {
    color: #0D1B2A;
}
.info_header h2 {
    font-family: "Montserrat-Light";
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}
.info_container {
    width: 100%;
    height: 80%;
}
.realtor_info_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 90%;
}
.realtor_info_content p {
    color: #0D1B2A;
}
.flex_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.details_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}