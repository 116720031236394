.onboarding_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/sign_in_background.svg');
    background-size: cover;
}
.sign_up_form, .realtor_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff99;
    border-radius: 10px;
    padding: 50px;
    height: 70%;
    overflow-y: auto;
    width: 35%;
}
.realtor_form h1, .realtor_form p, .sign_up_form p {
    color: #0D1B2A;
}
.realtor_form button:hover {
    color: #415A77;
}
.realtor_form input, .realtor_form select {
    margin-bottom: 5px;
    padding: 10px;
    border-width: 0px 0px 1px 0px;
    background: none;
    width: 80%;
}
.realtor_form button {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    color: #0D1B2A;
    width: 80%;
    border: none;
    margin-top: 15px;
    cursor: pointer;
}
#realtor_btn {
    color: #0D1B2A;
    background: none;
    width: 275px;
    padding: 10px;
    font-size: 12px;
    text-align: center;
}
#realtor_btn:hover {
    color: #415A77;
}
.sign_up_form input {
    margin-bottom: 5px;
    padding: 10px;
    border-width: 0px 0px 1px 0px;
    background: none;
    width: 80%;
}
.sign_up_form button {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    color: #0D1B2A;
    width: 80%;
    border: none;
    margin-top: 15px;
}

#back_button {
    align-self: flex-start;
    background: none;
    text-align: left;
}
.sign_up_form button:hover {
    color: #415A77;
    cursor: pointer;
}
.flex_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.margin_bottom {
    margin-bottom: 15px;
}
.margin_top {
    margin-top: 15px;
}
.background_white {
    background: white;
}
.width_90 {
    width: 90%;
}
.width_100 {
    width: 100%;
}
.realtor_form img, .sign_up_form img {
    height: 100px;
}
.realtor_form input:focus, .sign_up_form input:focus {
    outline: none;
}
.bold {
    font-weight: bold;
    font-size: x-large;
}
.helper_text {
    font-size: x-small;
    font-family: "Montserrat-Light";
    width: 80%;
    text-align: center;
    margin-top: 10px;
}
.grid_layout_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    width: 100%;
}
.payment_card {
    display: flex;
    flex-direction: column;
    background-color: #415A77;
    padding: 15px;
    border-radius: 10px;
}
.payment_card h1 {
    font-size: medium;
}
.payment_card h2 {
    font-size: 14px;
    color: white
}
.payment_card p {
    font-size: x-small;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #415A77;
    border-radius: 10px;
}
